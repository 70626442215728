/* material teaser */
.home-material-teaser {
  position: relative;
  display: block;
  margin: 0 0 30px;
  padding: 0;
  &.dark {
    h1, p {
      color: @darkgrey;
    }
    .c-category-slider {
      background: rgb(195, 195, 197);
      background: linear-gradient(0deg, rgba(195, 195, 197, 1) 0%, rgba(195, 195, 197, 1) 80%, rgba(255, 255, 255, 0) 100%);
    }
  }
  &.bright {
    h1, p {
      color: @white;
    }
    .c-category-slider {
      background: rgb(0, 0, 0);
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 80%, rgba(255, 255, 255, 0) 100%);
    }
  }
  h2 {
    position: relative;
    background: @white;
    padding: 20px 0;
    margin-bottom: -60px;
    text-align: center;
  }
  .content-wrapper {
    margin-top: -36%;
    h1, p {
      position: relative;
      display: none;
      margin: 0 15px;
      padding: 0 10px;
      text-align: center;
      text-shadow: 0 0 black;
    }
    h1 {
      font-size: 24px;
      text-transform: none;
      font-weight: 600;
    }
    p {
      padding-top: 10px;
      font-size: 14px;
      max-width: 420px;
    }
    .c-category-slider {
      position: relative;
      display: flex;
      justify-content: space-between;
      gap: 16px;
      padding: 30px 16px 20px 16px;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      scroll-padding: 16px;
      a {
        min-width: 140px;
        flex: 1 0 calc(13.3333333% - 16px);
        padding: 10px;
        background: @white;
        border-radius: 5%;
        text-align: center;
        scroll-snap-align: start;
        &:hover {
          text-decoration: none;
        }
        span {
          .sans-serif-light();
          font-size: 14px;
          color: @black;
        }
        img {
          margin: 0 auto 5px;
        }
      }
    }
    .prev, .next {
      position: absolute;
      bottom: 11%;
      display: block;
      width: 40px;
      height: 40px;
      cursor: pointer;
      border: 1px solid @mediumgrey;
      box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.3);
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.8);
      &:before, &:after {
        position: absolute;
        display: block;
        width: 12px;
        height: 12px;
        border-bottom-style: solid;
        border-bottom-width: 1px;
      }

      &:before {
        content: '';
        border-color: @darkgrey;
      }

      &:after {
        content: '';
        border-color: @white;
      }

      &.no-scroll {
        cursor: default;
        display: none;
      }
    }
    .prev {
      left: 5px;
      &:before, &:after {
        border-left-style: solid;
        border-left-width: 1px;
        left: 10px;
        transform: rotate(45deg);
        transform-origin: left bottom;
      }

      &:before {
        top: 6px;
        left: 14px;
      }

      &:after {
        top: 6px;
        left: 15px;
      }
    }
    .next {
      right: 5px;
      &:before, &:after {
        border-right-style: solid;
        border-right-width: 1px;
        right: 10px;
        transform: rotate(-45deg);
        transform-origin: right bottom;
      }

      &:before {
        top: 6px;
        right: 14px;
      }

      &:after {
        top: 6px;
        right: 15px;
      }
    }
  }
  @media (hover: hover) {
    .content-wrapper {
      .c-category-slider {
        .prev:hover, .next:hover {
          background: rgba(255, 255, 255, 1);
        }
        &.gold {
          a {
            &:hover {
              box-shadow: inset 0px 0px 3px 0 rgba(198, 181, 119, 1);
            }
          }
        }
        &.silver {
          a {
            &:hover {
              box-shadow: inset 0px 0px 3px 0 rgba(154, 152, 157, 1);
            }
          }
        }
      }
    }
  }
  .slider::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (min-width: 360px) {
  .home-material-teaser {
    h2 {
      padding: 24px 0;
      margin-bottom: -70px;
    }
    .content-wrapper {
      margin-top: -34%;
      .prev, .next {
        bottom: 10%;
      }
    }
  }
}
@media screen and (min-width: 420px) {
  .home-material-teaser {
    .content-wrapper {
      margin-top: -32%;
      .prev, .next {
        bottom: 9%;
      }
    }
  }
}
@media screen and (min-width:480px) {
  .home-material-teaser {
    h2 {
      padding: 20px 0;
      margin-bottom: -40px;
    }
    .content-wrapper {
      margin-top: -17%;
      .prev, .next {
        bottom: 13%;
      }
    }
  }
}
@media screen and (min-width: 560px) {
  .home-material-teaser {
    .content-wrapper {
      margin-top: -16%;
      .prev, .next {
        bottom: 12%;
      }
    }
  }
}
@media screen and (min-width: 640px) {
  .home-material-teaser {
    margin: 0 0 30px;
    .content-wrapper {
      margin-top: -20%;
      h1, p {
        display: block;
        text-align: left;
        max-width: 60%;
      }
      h1 {
        font-size: 28px;
      }
      p {
        font-size: 16px;
      }
      .prev, .next {
        bottom: 9%;
      }
      .prev {
        left: 8px;
      }
      .next {
        right: 8px;
      }
    }
  }
}
@media screen and (min-width: 769px){
  .home-material-teaser {
    margin: 0 0 70px;
    .content-wrapper {
      margin-top: -19%;
      h1, p {
        max-width: 50%;
      }
      .prev, .next {
        bottom: 9%;
      }
    }
  }
}
@media screen and (min-width: 880px) {
  .home-material-teaser {
    .content-wrapper {
      h1, p {
        max-width: 44%;
      }
      .prev, .next {
        bottom: 8%;
      }
    }
  }
}
@media screen and (min-width: 1024px){
  .home-material-teaser {
    h2 {
      padding: 24px 0;
      margin-bottom: -70px;
    }
    .content-wrapper {
      margin-top: -21%;
      h1, p {
        margin: 0 20px;
        max-width: 40%;
      }
      .c-category-slider {
        padding: 20px 30px;
        a {
          min-width: 120px;
        }
      }
      .prev, .next {
        display: none
      }
    }
  }
}
@media screen and (min-width: 1200px){
  .home-material-teaser {
    max-width: 1160px;
    margin: 0 auto 70px;
    .content-wrapper {
      h1, p {
        margin: 0 40px;
      }
      .c-category-slider {
        padding: 20px 50px 34px 50px;
      }
    }
  }
}