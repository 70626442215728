/* sprite-stream-teaser */
#live-teaser {
  position: relative;
  margin: 0 0 30px;
  padding: 30px 10px;
  color: @white;
  text-align: center;
  h2 {
    margin-bottom: 20px;
    a {
      color: @white;
    }
  }
  .fadePinkToLighter();
  .live-show-promo {
    position: relative;
    .live-show-title,
    .live-show-subtitle,
    .live-show-presenter,
    .button {
      display: none;
      margin: 0;
      background-color: transparent;
    }
  }
  .live-show-stream {
    &-video-container {
      
      display: block;
      position: relative;
      padding-top: 56.25%;
      overflow: hidden;
      
      video {
        position: absolute;
        display: block;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        object-fit: contain;
        
      }
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 80px;
        height: 80px;
        margin: -50px 0 0 -50px;
        border: 4px solid @white;
        border-radius: 100%;
        font-family: @icon;
        font-size: 30px;
        content: '\f04b';
        color: @white;
        text-align: center;
        line-height: 80px;
        text-indent: 4px;
        opacity: 0.9;
      }
    }
    .button {
      margin: 25px auto 0;
    }
  }
  .new-auction-link {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    margin: 0 0 20px;
    padding: 3px 10px 0;
    border: 1px solid @white;
    font-size: 15px;
    line-height: 27px;
    color: @white;
    &:after {
      display: inline;
      padding-left: 10px;
      float: right;
      font-family: @icon;
      font-size: 20px;
      text-align: center;
      content: '\e904';
    }
  }
}

/*
*/
.blackfriday #live-teaser {
  .fadeBlackToLighter();
  .live-show-promo {
    .button {
      background-color: @pink;
    }
  }
}

.bday #live-teaser {
  background: url('../images/backgrounds/bg_mobile_bday16liveauction.jpg');
  background-size: cover;
}
@media screen and (min-width: 640px){
  #live-teaser {
    padding: 0;
    color: @darkgrey;
    background: none;
    h2 {
      a {
        color: @darkgrey;
      }
    }
    .live-show {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      padding: 20px 10px;
      color: @white;
      .fadePinkToLighter();
    }
    .live-show-promo {
      flex: 0 0 50%;
      color: @white;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
      .live-show-title {
        display: block;
        .sans-serif-light();
        font-size: 24px;
        line-height: 24px;
        text-transform: uppercase;
      }
      .live-show-subtitle {
        display: block;
        margin-bottom: 10px;
        font-family: @serif-normal;
        font-size: 24px;
        line-height: 24px;
        font-style: italic;
        text-transform: none;
      }
      .live-show-title + .live-show-subtitle {
        margin-top: 10px;
      }
      .live-show-presenter {
        display: block;
        margin-bottom: 10px;
        .sans-serif-light();
        font-size: 14px;
        line-height: 14px;
      }
      .button {
        display: inline-block;
      }
    }
    .live-show-stream {
      box-sizing: border-box;
      padding: 0 10px 0 5px;
      flex: 0 0 50%;
      .x-image-container {}
      .button {
        display: none;
      }
    }
    .new-auction-link {
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 40px;
      .sans-serif-bold();
      border: none;
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        box-sizing: border-box;
        width: 41px;
        height: 40px;
        padding: 0 0 0 20px;
        background: linear-gradient(to top right, transparent 0%, transparent calc(50%), @white 50%, @white 100%);
        color: @pink;
      }
    }
  }
  /*
  */
  .blackfriday #live-teaser {
    background: none;
    .live-show {
      .fadeBlackToLighter();
    }
    .live-show-promo {
      .button {
        background-color: @pink;
      }
    }
  }
  .bday #live-teaser {
    background: none;
    h2, a, .button {
      color: @darkgrey;
    }
    .button {
      border-color: @darkgrey;
    }
    .live-show {
      background: url('../images/backgrounds/bg_desktop_bday16liveauction.jpg');
      background-size: cover;
    }
  }

  // spanien
  .es #live-teaser h2 {
    display: none;
  }
}
@media screen and (min-width: 768px){
  #live-teaser {
    margin: 0 10px 70px;
    h2 {
      margin-bottom: 28px;
    }
    .live-show-stream {
      padding: 0 40px 0 5px;
    }
  }
}
@media screen and (min-width: 769px){
  #live-teaser {
    margin: 0 20px 70px;
    .live-show {
      padding: 30px;
    }
    .live-show-stream {
      padding: 0 0 0 10px;
    }
  }
}
@media screen and (min-width: 1024px){
  #live-teaser {
    .live-show {
      padding: 40px;
    }
    .live-show-promo {
      .live-show-title {
        font-size: 30px;
        line-height: 30px;
      }
      .live-show-subtitle {
        font-size: 30px;
        line-height: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .live-show-presenter {
        font-size: 18px;
        line-height: 18px;
      }
      .button {
        margin-top: 30px;
      }
    }
    .new-auction-link {
      padding-right: 40px;
      line-height: 40px;
      &:after {
        width: 51px;
        height: 50px;
        padding: 0 0 0 25px;
      }
    }
  }
}
@media screen and (min-width: 1200px){
  #live-teaser {
    margin: 0 calc((100% - 1160px)/2) 70px;
  }
}