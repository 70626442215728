/* wheel */
.hero-teaser-wheel {
  .open-login-layer {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    padding-top: 127.9838709%;
  }
}
#main-teaser.wheel {
  padding: 0 5px;
  margin: 0 0 30px;
  .hero-slides {
    position: relative;
    width: 100%;
    padding: 0 0 127.9838709%;
  }
}
#segs {
  display: none;
}
#wheel {
  position: absolute;
  top: 2%;
  right: 5%;
  z-index: 2;
  box-sizing: border-box;
  width: 90%;
  max-width: 520px;
  padding: 0 5px;
  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 260px;
    margin-left: -130px;
    margin-top: -22px;
    padding: 12px 0 10px;
    border: 2px solid #ffffff;
    .hero-teaser-wheel {
      .open-login-layer {
        position: relative;
        padding-top: 127.9838709%;
      }
    }
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  .diamond {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    height: 40px;
    margin-top: -18px;
    font-size: 0;
    line-height: 0;
    text-align: center;
    background-image: url('../wheel/gluecksrad_diamant.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
#wheel-canvas {
  width: 100%;
  float: left;
}
#wheel-stats {
  display: none;
}
#voucher {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  box-sizing: border-box;
  width: 100%;
  padding: 0 5px;
}
#voucher-circle {
  position: relative;
  box-sizing: border-box;
  background-color: @white;
  border-width: 15px;
  border-style: solid;
  .rounded();
  margin: 0 auto;
  text-align: center;
  padding: 7.5%;
  &:before {
    top: -18px;
    margin-left: -10px;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    display: block;
    background-image: url('../wheel/needle.png');
    background-size: contain;
    content: ' ';
  }
  h2 {
    position: absolute;
    bottom: 77%;
    left: 25%;
    right: 25%;
    margin: 0;
    .serif-regular();
    font-size: 150%;
    line-height: 120%;
    text-transform: uppercase;
    small {
      vertical-align: text-bottom;
      font-size: 80%;
    }
  }
  .prize {
    position: absolute;
    bottom: 52.5%;
    left: 12.5%;
    right: 12.5%;
    margin: 0;
    .sans-serif-bold();
    font-size: 100%;
    line-height: 110%;
    #voucher-code {
      display: block;
      margin: 10px 0 0;
      font-size: 150%;
      &:after {
        display: inline-block;
        padding: 0 0 0 5px;
        font-family: @icon;
        content: '\f24d';
        transform: scaleY(-1);
      }
    }
  }
  .countdown {
    position: absolute;
    top: 52.5%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    line-height: 0;
    text-align: center;
    #countdown {
      display: inline-block;
      padding: 2.5% 5%;
      font-family: 'Courier New', 'Courier', monospace;
      font-weight: bold;
      color: #ffffff;
      font-size: 175%;
      line-height: 100%;
      .cbox {
        display: inline;
        &.days {
          display: none;
        }
        &.hours:after,
        &.minutes:after {
          display: inline;
          padding: 0 0.2em;
          content: ':';
        }
      }
    }
  }
  .voucher-link {
    position: absolute;
    top: 70%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    a {
      padding: 0;
      font-size: 120%;
      line-height: 100%;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: #575756;
      &:after {
        display: inline-block;
        padding-left: 5px;
        content: '>';
      }
    }
  }
  .hint {
    position: absolute;
    top: 85%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0 25%;
    line-height: 100%;
    small {
      font-size: 70%;
    }
  }
}
.color-scheme-blue {
  #wheel {
    .btn {
      background-color: #006e96;
    }
  }
  #voucher-circle {
    border-color: #9bcde6;
    &:before {
      background-image: url('../wheel/needle-blue.png');
    }
    h2 {
      color: #006e96;
    }
    .prize {
      .code {
        color: #32b4d7;
      }
    }
    .countdown {
      #countdown {
        background-color: #32b4d7;
      }
    }
  }
}
.color-scheme-pink {
  #wheel {
    .btn {
      background-color: @pink;
    }
  }
  #voucher-circle {
    border-color: #f5b4d2;
    h2 {
      color: @pink;
    }
    .prize {
      .code {
        color: #eb609b;
      }
    }
    .countdown {
      #countdown {
        background-color: #eb609b;
      }
    }
  }
}
.color-scheme-xmas {
  #wheel {
    .btn {
      background-color: #961e46;
    }
  }
  #voucher-circle {
    &:before {
      background-image: url('../wheel/needle-red.png');
    }
    border-color: #beb4aa;
    h2 {
      color: #961e46;
    }
    .prize {
      .code {
        color: #d70f41;
      }
    }
    .countdown {
      #countdown {
        background-color: #961e46;
      }
    }
  }
}
.color-scheme-green {
  #wheel {
    .btn {
      background-color: #507414;
    }
  }
  #voucher-circle {
    border-color: #d2dc5f;
    &:before {
      background-image: url('../wheel/needle-green.png');
    }
    h2 {
      color: #507414;
    }
    .prize {
      .code {
        color: #a5be1a;
      }
    }
    .countdown {
      #countdown {
        background-color: #507414;
      }
    }
  }
}
.color-scheme-gold {
  #wheel {
    .btn {
      background-color: @pink;
    }
  }
  #voucher-circle {
    border-color: #C7B37D;
    h2 {
      color: @pink;
    }
    .prize {
      .code {
        color: #eb609b;
      }
    }
    .countdown {
      #countdown {
        background-color: #eb609b;
      }
    }
  }
}

@media screen and (min-width: 513px) {
  #main-teaser.wheel {
    .hero-slides {
      padding: 0 0 50%;
    }
  }
  
  #wheel {
    top: 0;
    right: 5px;
    z-index: 2;
    width: 50%;
    .diamond {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 100%;
      height: 40px;
      margin-top: -18px;
      font-size: 0;
      line-height: 0;
      text-align: center;
      background-image: url('../wheel/gluecksrad_diamant.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  #voucher {
    right: 5px;
    z-index: 2;
    width: 50%;
  }
}
@media screen and (min-width: 681px){
  #voucher-circle {
    padding: 12.5% 7.5%; 
  }
}
@media screen and (min-width: 769px){
  #main-teaser.wheel {
    padding: 0 20px;
  }
}
@media screen and (min-width: 1025px){
  #main-teaser.wheel .hero-slides {
    padding: 0 0 percentage((1/3));
  }
  #wheel {
    top: 2px;
    width: percentage((1/3));
  }
  #voucher {
    top: 2px;
    width: percentage((1/3));
  }
}
@media screen and (min-width: 1200px){
  #main-teaser.wheel {
    margin: 0 auto;
  }
  #voucher-circle {
    padding: 12.5% 7.5%;
  }
}