.home-advantages-banner {
  margin: 0 0 70px;
  padding: 0 5px;
  display: flex;
  flex-wrap: wrap;
  h2 {
    flex: 1 0 100%;
  }
  div {
    flex: 1 0 calc(50% - 10px);
    padding: 0 5px;
  }
  picture {
    display: block;
    margin: 0 0 10px;
    padding: calc((50% - 90px)/2) 0;
    border: 1px solid @mediumgrey;
    background-color: @white;
    img {
      max-width: 180px;
      margin: 0 auto;
    }
    h3 {}
    p {}
  }
}
@media screen and (min-width: 768px) {
  .home-advantages-banner {
    div {
      flex: 1 0 calc(25% - 10px);
    }
  }
}
@media screen and (min-width: 769px) {
  .home-advantages-banner {
    padding: 0 10px;
    div {
      flex: 1 0 calc(25% - 20px);
      padding: 0 10px;
    }
  }
}
@media screen and (min-width: 1024px) {
  .home-advantages-banner {
    padding: 0 15px;
    div {
      flex: 1 0 calc(25% - 30px);
      padding: 0 15px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .home-advantages-banner {
    margin: 0 0 70px;
    padding: 0 calc((100% - 1160px)/2);
    div {
      flex: 1 0 calc(25% - 40px);
      padding: 0 20px;
    }
  }
}