/* Jewelry Finder Widget */
.jewelry-widget {
  position: relative;
  margin: 0 10px 30px;
  font-size: 0;
  line-height: 0;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  /*background-color: #ffe5f1;*/
  background-color: @white;

  .nolink {
    position: relative;
    padding-top: 100%;
  }
  .silver {
    background: none;
    .silver {
      opacity: 1;
    }
    .gold {
      opacity: 0;
    }
  }
  .gold {
    background: none;
    .gold {
      opacity: 1;
    }
    .silver {
      opacity: 0;
    }
  }
  .section-wrapper {
    position: relative;
    width: 300%;
    &.q1 {
      margin-left: 0;
      transition: margin-left 0.5s;
    }
    &.q2 {
      margin-left: -100%;
      transition: margin-left 0.5s;
    }
    &.q3 {
      margin-left: -200%;
      transition: margin-left 0.5s;

      .silver {
        background: none;
        .silver {
          display: block;
          opacity: 1;
        }
        .gold {
          display: none;
          opacity: 0;
        }
      }
      .gold {
        background: none;
        .gold {
          display: block;
          opacity: 1;
        }
        .silver {
          display: none;
          opacity: 0;
        }
      }
    }
  }
  .jewelry-widget-section {
    position: relative;
    float: left;
    box-sizing: border-box;
    width: percentage((1/3));
    padding: 0 5px;
    transition: left 0.5s;
    .jewelry-widget-section-title {
      position: relative;
      left: 0;
      bottom: 0;
      width: 100%;
      font-size: 18px;
      line-height: 22px;
    }
    .jewelry-widget-section-options {
      position: relative;
      .jewelry-widget-section-option {
        position: relative;
        display: inline-block;
        width: 50%;
        float: left;
        box-sizing: border-box;
        padding: 0 5px;
        margin: 0 0 10px;
        font-size: 0;
        line-height: 0;
        cursor: pointer;
        img {
          float: left;
        }
        .attribute-label {
          position: absolute;
          left: 5px;
          right: 5px;
          bottom: 0;
          z-index: 2;
          display: block;
          padding: 10px 0 8px;
          .sans-serif-regular();
          font-size: 12px;
          line-height: 15px;
          color: @white;
          background-color: @juwelogold;
        }
        &.checked {
          .attribute-label {
            &:after {
              display: inline;
              padding-left: 5px;
              font-family: @icon;
              content: '\f00c';
            }
          }
        }
      }
    }
  }
  .jewelry-widget-section-tabs {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin: 18px 0 0;
    padding: 0 10px;
    text-align: center;
    text-transform: none;
    li {
      position: relative;
      box-sizing: border-box;
      display: block;
      width: percentage((1/3));
      margin: 0;
      padding: 0;
      float: left;
      .sans-serif-regular();
      font-size: 12px;
      text-align: center;
      color: @juwelolightgold;
      .text {
        display: block;
        padding: 0 10px;
        line-height: 16px;
      }
      &:before {
        position: relative;
        z-index: 2;
        display: block;
        width: 32px;
        height: 32px;
        margin: 0 auto 10px;
        .sans-serif-regular();
        line-height: 32px;
        text-align: center;
        border: 1px solid @juwelolightgold;
        .rounded();
        background-color: @white;
      }
      .line {
        position: absolute;
        top: 18px;
        display: block;
        width: 50%;
        &:before {
          display: block;
          content: ' ';
          border-top: 1px solid @juwelolightgold;
        }
      }
      &:nth-child(1) {
        &:before {
          content: '1';
        }
        .line {
          left: 50%;
          &:before {
            margin: 0 0 0 26px;
          }
        }
      }
      &:nth-child(2) {
        &:before {
          content: '2';
        }
        .line:nth-child(1) {
          left: 0;
          &:before {
            margin: 0 26px 0 0;
          }
        }
        .line:nth-child(2) {
          left: 50%;
          &:before {
            margin: 0 0 0 26px;
          }
        }
      }
      &:nth-child(3) {
        &:before {
          content: '3';
        }
        .line {
          left: 0;
          &:before {
            margin: 0 26px 0 0;
          }
          &:nth-child(2){
            display: none;
          }
        }
      }
      &:nth-child(4) {
        display: none;
      }
      &.current {
        color: @juwelogold;
        &:before {
          border-color: lighten(@juwelogold, 10%);
        }
        .line {
          &:before {
            border-color: @juwelolightgold;
          }
          &:nth-child(2) {
            &:before {
              border-color: @juwelolightgold;
            }
          }
        }
      }
      &:nth-child(1){
        &.current {
          .line {
            &:before {
              border-color: @juwelolightgold;
            }
          }
        }
      }
      &.done {
        color: @juwelogold;
        &:before {
          font-family: @icon;
          content: '\f00c';
          border-color: lighten(@juwelogold, 10%);
          color: @juwelogold;
        }
        .line {
          &:before {
            border-color: lighten(@juwelogold, 10%);
          }
        }
      }
    }
  }
  #widget-message {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(255,255,255,0.5);
    p {
      padding: 50% 0;
      .sans-serif-bold();
      font-size: 15px;
      line-height: 20px;
      text-align: center;
    }
  }
}
@media screen and (min-width: 481px){
  .jewelry-widget {
    .jewelry-widget-section {
      .jewelry-widget-section-options {
        .jewelry-widget-section-option {
          .attribute-label {
            font-size: 15px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 640px){
  .jewelry-widget {
    .jewelry-widget-section {
      .jewelry-widget-section-options {
        &.options-count-2 {
          width: 50%;
          margin: 0 auto;
          .jewelry-widget-section-option {
            width: 50%;
          }
        }
        .jewelry-widget-section-option {
          width: 25%;
          .attribute-label {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 641px){
  .jewelry-widget {
    #widget-message {
      p {
        padding: 15% 0;
      }
    }
  }
}
@media screen and (min-width: 768px){
  .jewelry-widget {
    margin: 0 30px 70px;
    .jewelry-widget-section {
      .jewelry-widget-section-title {
        font-size: 22px;
      }
      .jewelry-widget-section-options {
        .jewelry-widget-section-option {
          .attribute-label {
            padding: 8px 0;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
    .jewelry-widget-section-tabs {
      li {
        font-size: 18px;
        .text {
          padding: 0 10px;
          line-height: 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 769px){
  .jewelry-widget {
    .jewelry-widget-section {
      padding: 0 10px;
      .jewelry-widget-section-options {
        .jewelry-widget-section-option {
          padding: 0 10px;
          .attribute-label {
            left: 10px;
            right: 10px;
          }
        }
      }
    }
    .jewelry-widget-section-tabs {
      li {
        .text {
          padding: 0 20px;
        }
        &:nth-child(1),
        &:nth-child(3) {
          width: 25%;
        }
        &:nth-child(2) {
          width: 50%;
        }
        &:before {
          border-width: 2px;
        }
        .line {
          &:before {
            border-width: 2px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1025px){
  .jewelry-widget {
    .jewelry-widget-section {
      .jewelry-widget-section-options {
        .jewelry-widget-section-option {
          .attribute-label {
            .sans-serif-light();
            font-size: 20px;
            line-height: 25px;
          }
        }
      }
    }
    .jewelry-widget-section-tabs {
      li {
        font-size: 20px;
        .text {
          .sans-serif-light();
          line-height: 25px;
        }
        &:nth-child(1) {
          .line {
            left: 50%;
            &:before {
              margin: 0 0 0 24px;
            }
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: 25%;
          .line:nth-child(1) {
            left: 0;
            &:before {
              margin: 0 24px 0 0;
            }
          }
          .line:nth-child(2) {
            display: block;
            left: 50%;
            &:before {
              margin: 0 0 0 24px;
            }
          }
        }
        &:nth-child(4) {
          display: block;
          width: 25%;
          &:before {
            content: '4';
          }
          .line {
            left: 0;
            &:before {
              margin: 0 24px 0 0;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200px){
  .cms-index-index .jewelry-widget {
    margin: 0 calc((100% - 1140px)/2) 70px;
  }
}

// category
.catalog-category-view {

  // A/B-Test switch
  .category-top-widget-test {
    position: relative;
    .col.category-cms.category-teaser {
      display: none;
    }
  }

  .jewelry-widget.category {
    box-sizing: border-box;
    margin: 0 0 40px;
    padding: 0 0 10px;
    background-color: @white;
    .sticky-element-shadow();
    .close-icon {
      top: 7px;
      right: 0;
      &:after {
        width: 30px;
        height: 30px;
        background-color: @white;
        color: @black;
        font-family: @icon;
        font-size: 16px;
        line-height: 30px;
        content: '\e901';
      }
    }
    .jewelry-widget-title {
      margin: 20px 10px 0;
      font-size: 15px;
      line-height: 20px;
      text-transform: none;
      text-align: left;
      border-bottom: 1px solid @mediumgrey;
      strong {
        .sans-serif-bold();
      }
    }
    .jewelry-widget-section-tabs {
      position: relative;
      margin: 0;
      padding: 0;
      width: 100%;
      float: left;
      li.jewelry-widget-section-tab {
        position: relative;
        width: 35%;
        padding: 0;
        font-size: 0;
        line-height: 0;
        color: @darkgrey;
        border-bottom: 1px solid @mediumgrey;
        .nolink {
          position: relative;
          display: inline-block;
          width: 75px;
          height: 50px;
          margin: 0 0 0 15%;
          clear: both;
          padding-top: 0;
          img {
            width: auto;
            height: 50px;
          }
        }
        .text {
          display: block;
          padding: 0;
          font-size: 10px;
          line-height: 20px;
          &:before {
            display: inline;
            border: none;
            margin: 0 2px 0 0;
            font-size: 10px;
            line-height: 19px;
            color: @darkgrey;
            background-color: transparent;
          }
        }
        &:before {
          display: none;
        }
        &:after {
          position: absolute;
          top: 10px;
          right: -27px;
          z-index: 2;
          transform: rotate(45deg);
          display: block;
          width: 49px;
          height: 49px;
          border-top: 1px solid @mediumgrey;
          border-right: 1px solid @mediumgrey;
          content: '';
        }
        &:nth-child(2) {
          width: 30%;
          .text:before {
            content: '1.';
          }
        }
        &:nth-child(3) {
          .text:before {
            content: '2.';
          }
        }
        &:nth-child(4) {
          display: block;
          &:after {
            display: none;
          }
          .text:before {
            content: '3.';
          }
        }
        &.current {
          color: @pink;
          border-bottom-color: @pink;
          .text:before {
            color: @pink;
          }
        }
        &.done {
          .text:before {
            font-family: @icon;
            font-size: 10px;
            content: '\e902';
          }
        }
      }
      #start-button-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        width: 100%;
        &:before {
          display: none;
        }
        .btn {
          position: absolute;
          top: 19px;
          left: 20px;
          right: 70%;
        }
      }
    }
    .jewelry-widget-section {
      padding: 0;
      h2 {
        margin: 20px 10px 5px;
        font-size: 12px;
      }
      .jewelry-widget-section-options {
        width: 100%;
        max-width: 600px;
        margin: 0 auto 20px;
        box-sizing: border-box;
        padding: 0 5px;
        .jewelry-widget-section-option {
          width: 25%;
          margin: 0 0 20px;
          padding: 0 5px;
          .nolink {
            border: 1px solid @mediumgrey;
          }
          .attribute-label {
            position: absolute;
            top: 100%;
            left: 1px;
            right: 1px;
            bottom: auto;
            padding: 3px 0 1px;
            font-size: 10px;
            text-transform: none;
            color: @darkgrey;
            background-color: transparent;
          }
        }
        &.options-count-2 {
          .jewelry-widget-section-option:first-child {
            margin-left: 25%;
          }
          .jewelry-widget-section-option:nth-child(2) {
            margin-right: 25%;
          }
        }
      }
    }
    &.start {
      margin: 0 0 20px;
      .no-shadow();
      .close-icon {
        display: none;
      }
      .jewelry-widget-title {
        text-align: center;
      }
      .jewelry-widget-section-tabs {
        li.jewelry-widget-section-tab {
          &:nth-child(2) {
            .text,
            &:before {
              opacity: 0;
            }
          }
        }
        li.jewelry-widget-section-tab-1 {
          .x-image-container {
            opacity: 0;
          }
        }
      }
    }

    .jewelry-widget-result {
      z-index: -1;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 110px;
      float: left;
      text-align: center;
      h2 {
        margin: 20px 0 0;
        font-size: 15px;
        line-height: 20px;
        text-transform: none;
        border-bottom: 1px solid @mediumgrey;
        strong {
          .sans-serif-bold();
        }
      }
      p {
        .sans-serif-regular();
        font-size: 13px;
        line-height: 15px;
        text-transform: none;
        margin: 0 0 15px;
        padding: 15px 0 0;
      }
      .btn-restart {
        display: inline-block;
        font-size: 13px;
        line-height: 17px;
        text-transform: none;
      }
    }

    &.faded-out {
      display: none;
    }
  }
}
@media screen and (min-width: 769px){
  .catalog-category-view {
    .jewelry-widget.category {
      margin: 0 0 40px;
      padding: 0 10px 10px;
      .jewelry-widget-title {
        margin: 20px 10px 0;
      }
      .jewelry-widget-section-tabs {
        padding: 0 10px;
        li.jewelry-widget-section-tab {
          .nolink {
            width: 120px;
            height: 80px;
            img {
              height: 80px;
            }
          }
          .text {
            font-size: 13px;
            &:before {
              font-size: 13px;
            }
          }
          &:after {
            top: 14px;
            right: -35px;
            width: 71px;
            height: 71px;
          }
          &.done {
            .text:before {
              font-size: 13px;
              line-height: 16px;
            }
          }
        }
        #start-button-container {
          .btn {
            top: 34px;
          }
        }
      }
      .jewelry-widget-section {
        padding: 0;
        .jewelry-widget-section-options {
          padding: 0;
          float: none;
          .jewelry-widget-section-option {
            margin-bottom: 0;
            padding: 0 10px;
            .x-image-container {
              left: 10px;
              right: 10px;
            }
            .attribute-label {
              top: auto;
              bottom: 0;
              font-size: 13px;
            }
          }
        }
      }

      .jewelry-widget-result {
        padding: 0 20px;
      }
    }
  }
}
@media screen and (min-width: 1025px){
  .catalog-category-view {
    .category-top-widget-test {
      width: 100%;
      padding-top: 15.928571%;
      margin: 0 0 20px;
      &.widget-started {
        height: 200px;
      }
      .col.category-cms.category-teaser {
        display: none;
      }
    }
    .jewelry-widget.category {
      display: none;
      .no-shadow();
    }
    //
    &.show-widget {
      .jewelry-widget.category {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    &.show-banner {
      .category-top-widget-test {
        .col.category-cms.category-teaser {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 0 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 1140px){
  .catalog-category-view {
    .category-top-widget-test {
      &.widget-started {
        height: 180px;
      }
    }
  }
}