.home-center-promo {
  position: relative;
  margin: 0 5px 20px;
  text-align: center;
  font-size: 0;
  line-height: 0;
  &.brands {
    border-top: 1px solid @pink;
    border-bottom: 1px solid @pink;
  }
  .brand-link {
    display: inline-block;
    width: 33%;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
@media screen and (min-width: 769px) {
  .home-center-promo {
    margin: 20px 10px;
    .brand-link {
      width: 16%;
    }
  }
}

// cls-fix

.home-center-promo {
  a,.nolink {
    display: block;
    position: relative;
    padding-top: 4.1666666%;

    @media screen and (max-width: 520px) {
      padding-top: 25%;
    }
  }
  &.brands {
    // image aspect ratio 4:1, 3 per row
    a {
      padding-top: percentage((1/12));
    }
  }
}
@media screen and (max-width: 520px) {
  .home-center-promo {
    a,.nolink {
      padding-top: 25%;
    }
  }
}
@media screen and (min-width: 769px) {
  .home-center-promo {
    // image aspect ratio 4:1, 6 per row
    &.brands {
      a {
        padding-top: percentage((1/24));
      }
    }
  }
}
.bottom-promo {
  a {
    display: block;
    position: relative;
    padding-top: 50%;
  }
}
@media screen and (min-width: 520px) {
  .bottom-promo {
    a {
      padding-top: 16.129032%;
    }
  }
}