/* dice game hero */

#dices {
  position: relative;
  .dice-game {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  #board {
    position: relative;
    top: 19%;
    width: 100%;
    height: 200px;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    button,
    #jewelry-link {
      position: absolute;
      left: 0;
      bottom: 50px;
      width: 66%;
      margin: 0 17%;
    }
    #jewelry-link {
      padding: 12px 32px 10px;
    }
    #shake-text {
      position: absolute;
      left: 0;
      bottom: 50px;
      width: 100%;
      .sans-serif-bold();
      font-size: 12px;
      line-height: 15px;
      color: @pink;
    }
    #dice-throws-none-left,
    #dice-throws-left {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      .sans-serif-bold();
      font-size: 12px;
      line-height: 15px;
      color: @pink;
    }
  }
  #dice-game-voucher {
    position: absolute;
    bottom: 64px;
    width: 74%;
    margin: 0 13%;
    background-color: rgba(255,255,255,1);
    box-sizing: border-box;
    padding: 5px 10px;
    p {
      margin: 0;
      font-size: 13px;
      line-height: 20px;
      strong {
        .sans-serif-bold();
      }
      .pink {
        color: @pink;
      }
    }
  }
  #dice-game-conditions {
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 0 5% 4%;
    p {
      margin: 0;
      font-size: 8px;
      line-height: 11px;
    }
  }
  .dice {
    align-items: center;
    display: grid;
    grid-gap: 0%;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    grid-template-rows: auto;
    justify-items: center;
    padding: 10px;
    perspective: 500px;
    .dice-list {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      width: 30px;
      height: 30px;
      list-style-type: none;
      transform-style: preserve-3d;
      .dice-item {
        margin: 0;
        background-color: @pink;
        box-shadow:
                  inset -3px 3px 6px rgba(0, 0, 0, 0.3),
                inset 3px -3px 6px rgba(0, 0, 0, 0.15);
        display: grid;
        grid-column: 1;
        grid-row: 1;
        grid-template-areas:
                "one two three"
                "four five six"
                "seven eight nine";
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        height: 100%;
        width: 100%;
        padding: 8px;
        border-radius: .4rem;
        .dot {
          align-self: center;
          background-color: @white;
          border-radius: 50%;
          box-shadow: inset -0.15rem 0.15rem 0.25rem rgba(0, 0, 0, 0.5);
          display: block;
          height: 10px;
          width: 10px;
          justify-self: center;
        }
      }
    }
    .even-roll {
      transition: transform 1.5s ease-out;
    }
    .odd-roll {
      transition: transform 1.25s ease-out;
    }
    .even-roll[data-roll="0"] {
      transform: rotateX(130deg) rotateY(226deg) rotateZ(280deg);
    }
    .even-roll[data-roll="1"] {
      transform: rotateX(360deg) rotateY(720deg) rotateZ(360deg);
    }
    .even-roll[data-roll="2"] {
      transform: rotateX(450deg) rotateY(720deg) rotateZ(360deg);
    }
    .even-roll[data-roll="3"] {
      transform: rotateX(360deg) rotateY(630deg) rotateZ(360deg);
    }
    .even-roll[data-roll="4"] {
      transform: rotateX(360deg) rotateY(810deg) rotateZ(360deg);
    }
    .even-roll[data-roll="5"] {
      transform: rotateX(270deg) rotateY(720deg) rotateZ(360deg);
    }
    .even-roll[data-roll="6"] {
      transform: rotateX(360deg) rotateY(900deg) rotateZ(360deg);
    }
    .odd-roll[data-roll="0"] {
      transform: rotateX(-130deg) rotateY(-300deg) rotateZ(-270deg);
    }
    .odd-roll[data-roll="1"] {
      transform: rotateX(-360deg) rotateY(-720deg) rotateZ(-360deg);
    }
    .odd-roll[data-roll="2"] {
      transform: rotateX(-270deg) rotateY(-720deg) rotateZ(-360deg);
    }
    .odd-roll[data-roll="3"] {
      transform: rotateX(-360deg) rotateY(-810deg) rotateZ(-360deg);
    }
    .odd-roll[data-roll="4"] {
      transform: rotateX(-360deg) rotateY(-630deg) rotateZ(-360deg);
    }
    .odd-roll[data-roll="5"] {
      transform: rotateX(-450deg) rotateY(-720deg) rotateZ(-360deg);
    }
    .odd-roll[data-roll="6"] {
      transform: rotateX(-360deg) rotateY(-900deg) rotateZ(-360deg);
    }
    [data-side="1"] {
      transform: rotate3d(0, 0, 0, 90deg) translateZ(30px);
    }
    [data-side="2"] {
      transform: rotate3d(-1, 0, 0, 90deg) translateZ(30px);
    }
    [data-side="3"] {
      transform: rotate3d(0, 1, 0, 90deg) translateZ(30px);
    }
    [data-side="4"] {
      transform: rotate3d(0, -1, 0, 90deg) translateZ(30px);
    }
    [data-side="5"] {
      transform: rotate3d(1, 0, 0, 90deg) translateZ(30px);
    }
    [data-side="6"] {
      transform: rotate3d(1, 0, 0, 180deg) translateZ(30px);
    }
    [data-side="1"] .dot:nth-of-type(1) {
      grid-area: five;
    }
    [data-side="2"] .dot:nth-of-type(1) {
      grid-area: one;
    }
    [data-side="2"] .dot:nth-of-type(2) {
      grid-area: nine;
    }
    [data-side="3"] .dot:nth-of-type(1) {
      grid-area: one;
    }
    [data-side="3"] .dot:nth-of-type(2) {
      grid-area: five;
    }
    [data-side="3"] .dot:nth-of-type(3) {
      grid-area: nine;
    }
    [data-side="4"] .dot:nth-of-type(1) {
      grid-area: one;
    }
    [data-side="4"] .dot:nth-of-type(2) {
      grid-area: three;
    }
    [data-side="4"] .dot:nth-of-type(3) {
      grid-area: seven;
    }
    [data-side="4"] .dot:nth-of-type(4) {
      grid-area: nine;
    }
    [data-side="5"] .dot:nth-of-type(1) {
      grid-area: one;
    }
    [data-side="5"] .dot:nth-of-type(2) {
      grid-area: three;
    }
    [data-side="5"] .dot:nth-of-type(3) {
      grid-area: five;
    }
    [data-side="5"] .dot:nth-of-type(4) {
      grid-area: seven;
    }
    [data-side="5"] .dot:nth-of-type(5) {
      grid-area: nine;
    }
    [data-side="6"] .dot:nth-of-type(1) {
      grid-area: one;
    }
    [data-side="6"] .dot:nth-of-type(2) {
      grid-area: three;
    }
    [data-side="6"] .dot:nth-of-type(3) {
      grid-area: four;
    }
    [data-side="6"] .dot:nth-of-type(4) {
      grid-area: six;
    }
    [data-side="6"] .dot:nth-of-type(5) {
      grid-area: seven;
    }
    [data-side="6"] .dot:nth-of-type(6) {
      grid-area: nine;
    }
  }
}
.de #dices #voucher-value:after { content: '%'; }
.en #dices #voucher-value:before { content: '%'; }
.es #dices #voucher-value:after { content: '%'; }
.fr #dices #voucher-value:after { content: '%'; }
.it #dices #voucher-value:after { content: '%'; }
.nl #dices #voucher-value:before { content: '%'; }
@media screen and (min-width: 360px) {
  #dices {
    #board {
      top: 27%;
    }
    #dice-game-voucher {
      p {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}
@media screen and (min-width: 400px) {
  #dices {
    #dice-game-voucher {
      p {
        font-size: 18px;
        line-height: 34px;
      }
    }
  }
}
@media screen and (min-width: 480px) {
  #dices {
    #dice-game-voucher {
      p {
        font-size: 24px;
        line-height: 40px;
      }
    }
  }
}
@media screen and (min-width: 540px) {
  #dices {
    #dice-game-voucher {
      p {
        font-size: 28px;
        line-height: 46px;
      }
    }
  }
}
@media screen and (min-width: 640px) {
  #dices {
    #board {
      position: absolute;
      top: 50%;
      left: 75%;
      right: auto;
      bottom: 1%;
      max-width: 200px;
      height: 200px;
      margin: -100px 0 0 -100px;
      #dice-throws-none-left,
      #dice-throws-left {
        font-size: 10px;
        line-height: 12px;
      }
    }
    #dice-game-voucher {
      top: auto;
      left: 4%;
      bottom: 22%;
      width: auto;
      margin: 0;
      padding: 0 0 0 10px;
      p {
        float: left;
        margin: 0 10px 0 0;
        font-size: 14px;
        line-height: 30px;
      }
    }
    #dice-game-conditions {
      left: 0;
      width: 50%;
      padding: 0 4% 1.5%;
    }
  }
}
@media screen and (min-width: 768px) {
  #dices {
    #board {
      position: absolute;
      top: 50%;
      left: 75%;
      right: auto;
      bottom: 1%;
      max-width: 300px;
      height: 244px;
      margin: -122px 0 0 -150px;
      #dice-throws-none-left,
      #dice-throws-left {
        font-size: 12px;
        line-height: 15px;
      }
    }
    .dice {
      width: 300px;
      padding: 10px;
      .dice-list {
        width: 50px;
        height: 50px;
        .dice-item {
          padding: 9px;
          .dot {
            height: 12px;
            width: 12px;
          }
        }
      }
      [data-side="1"] {
        transform: rotate3d(0, 0, 0, 90deg) translateZ(33px);
      }
      [data-side="2"] {
        transform: rotate3d(-1, 0, 0, 90deg) translateZ(33px);
      }
      [data-side="3"] {
        transform: rotate3d(0, 1, 0, 90deg) translateZ(33px);
      }
      [data-side="4"] {
        transform: rotate3d(0, -1, 0, 90deg) translateZ(33px);
      }
      [data-side="5"] {
        transform: rotate3d(1, 0, 0, 90deg) translateZ(33px);
      }
      [data-side="6"] {
        transform: rotate3d(1, 0, 0, 180deg) translateZ(33px);
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  #dices {
    .dice {
      .dice-list {
        width: 60px;
        height: 60px;
        .dice-item {
          padding: 10px;
          .dot {
            height: 15px;
            width: 15px;
          }
        }
      }
      [data-side="1"] {
        transform: rotate3d(0, 0, 0, 90deg) translateZ(40px);
      }
      [data-side="2"] {
        transform: rotate3d(-1, 0, 0, 90deg) translateZ(40px);
      }
      [data-side="3"] {
        transform: rotate3d(0, 1, 0, 90deg) translateZ(40px);
      }
      [data-side="4"] {
        transform: rotate3d(0, -1, 0, 90deg) translateZ(40px);
      }
      [data-side="5"] {
        transform: rotate3d(1, 0, 0, 90deg) translateZ(40px);
      }
      [data-side="6"] {
        transform: rotate3d(1, 0, 0, 180deg) translateZ(40px);
      }
    }
  }
}