.c-latest-auctions-teaser {
  margin: 0 0 50px;
  .fadePinkToLighter();
  h2 {
    text-align: center;
    color: @white;
  }
}
/*
*/
.blackfriday .c-latest-auctions-teaser {
  .fadeBlackToLighter();
}

#live-teaser + .c-latest-auctions-teaser {
  margin: -30px 0 50px;
  padding: 0 10px;
  position: relative;
}

.c-latest-auctions-teaser  {
  box-sizing: border-box;
  padding: 10px 10px 0;
  text-align: center;
  ul {
    position: relative;
    display: grid;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    padding: 0;
    li {
      position: relative;
      user-select: none;
      margin: 0;
      grid-row: 1;
      &:first-child {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 2;
      }
      &:nth-child(2){
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 3;
      }
      &:nth-child(3){
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(4){
        grid-row-start: 2;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 3;
      }
      &:nth-child(5){
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(6){
        grid-row-start: 3;
        grid-row-end: 4;
        grid-column-start: 3;
        grid-column-end: 4;
      }
      a {
        display: block;
        position: relative;
        box-sizing: border-box;
        & > .brand {
          position: absolute;
          top: 5px;
          left: 5px;
          z-index: 2;
          width: 50px;
        }
      }
    }
  }
  .button {
    margin: 20px auto;
  }
}
.bday {
  .c-latest-auctions-teaser {
    h2 {
      margin: 0;
      padding: 20px 0;
    }
  }
}

@media screen and (min-width: 480px){
  .c-latest-auctions-teaser  {
    ul {
      li {
        &:first-child {
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 1;
          grid-column-end: 2;
        }
        &:nth-child(2){
          grid-row-start: 2;
          grid-row-end: 3;
          grid-column-start: 1;
          grid-column-end: 2;
        }
        &:nth-child(3){
          grid-row-start: 1;
          grid-row-end: 3;
          grid-column-start: 2;
          grid-column-end: 4;
        }
        &:nth-child(4){
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 4;
          grid-column-end: 5;
        }
        &:nth-child(5){
          grid-row-start: 2;
          grid-row-end: 3;
          grid-column-start: 4;
          grid-column-end: 5;
        }
        &:nth-child(6){
          grid-row-start: 1;
          grid-row-end: 3;
          grid-column-start: 5;
          grid-column-end: 7;
        }
      }
    }
  }
}
@media screen and (min-width: 768px){
  .c-latest-auctions-teaser {
    margin: 0 10px 90px;
  }
  #live-teaser + .c-latest-auctions-teaser {
    margin: -70px 10px 90px;
  }
}
@media screen and (min-width: 769px){
  #live-teaser + .c-latest-auctions-teaser {
    margin: -70px 20px 90px;
    padding: 0 30px;
  }
  .c-latest-auctions-teaser {
    margin: 0 20px 90px;
    padding: 30px 30px 0;
  }
}
@media screen and (min-width: 1024px){
  #live-teaser + .c-latest-auctions-teaser {
    padding: 0 40px;
  }
  .c-latest-auctions-teaser  {
    padding: 40px 40px 0;
    ul {
      grid-row-gap: 20px;
      grid-column-gap: 20px;
      li {
        box-shadow: 0 0 12px -3px #000;
        &:first-child {
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 1;
          grid-column-end: 2;
        }
        &:nth-child(2){
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 2;
          grid-column-end: 3;
        }
        &:nth-child(3){
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 3;
          grid-column-end: 4;
        }
        &:nth-child(4){
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 4;
          grid-column-end: 5;
        }
        &:nth-child(5){
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 5;
          grid-column-end: 6;
        }
        &:nth-child(6){
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 6;
          grid-column-end: 7;
        }
      }
    }
    .button {
      margin: 40px auto;
    }
  }
}
@media screen and (min-width: 1200px){
  #live-teaser + .c-latest-auctions-teaser {
    margin: -70px calc((100% - 1160px)/2) 90px;
  }
  .c-latest-auctions-teaser  {
    margin: 0 calc((100% - 1160px)/2) 90px;
  }
}