/* variabes */
@white: #FFFFFF;
@pink: #B4146E;
@lightpink: #f5b4d2;
@pink50: rgba(180,20,110,0.5);
@purple: #A24B90;
@lightpurple: #e7d1e2;
@red: #d32f2f;
@lightred: #f6d6d6;
@blue: #0E3ECE;
@lightblue: #c1cdf2;
@green: #139b1f;
@lightgreen: #d0ebd3;
@yellow: #FFD164;
@orange: #FF993B;
@brown: #6F3B24;
@lightgrey: #F2F2F2;
@lightgreyfooter: #DEDEDE;
@mediumgrey: #CCCCCC;
@mediumgreyfooter: #C2C2C2;
@mediumgreybutton: #999997;
@darkgrey: #575756;
@black: #111111;
@silver: #C6C6C6;
@lightgold: #F3F0EB;
@juwelolightgold: #ddd7c7;
@gold: #B3A797;
@juwelogold: #9b8c77;
@rosegold: #eabfb9;
@brass: #b3994b;
@titanium: #7f7f7f;
@form-element-height: 40px;
@serif-normal: 'Georgia', 'Times', serif;
@icon: 'FontAwesome', monospace;
@icons: 'Icons', monospace;
@shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
@darkshadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
@form-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
@popup-shadow: 0px 2px 10px rgba(0, 0, 0, 0.75);
@navshadow: 3px 5px 5px rgba(0, 0, 0, 0.5);
@text-shadow: 0px 0px 2px rgba(255, 255, 255, 1);
@sticky-element-shadow: 0px 0px 21px 3px rgba(51,51,51,0.8);
@buttonshadow: 0px 0px 3px rgba(0, 0, 0, 0.5);

/* mixin classes */

.input-box-label-super-position(){
  top: -7px;
  left: 7px;
  z-index: 3;
  padding: 0 3px;
  background: @white;
  background: linear-gradient(0deg, white 0%, white 50%, transparent 50%, transparent 100%);
  font-size: 11px;
  line-height: 11px;
}
.input-box-label-placeholder-position(){
  top: 0;
  left: 10px;
  z-index: 3;
  padding: 0;
  font-size: 16px;
  line-height: 38px;
  color: @mediumgrey;
}

.sans-serif-light(){
  font-family: "Heebo", "Helvetica Neue", "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  letter-spacing: 0.03em;
}
.sans-serif-regular(){
  font-family: "Heebo", "Helvetica Neue", "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  letter-spacing: 0.01em;
}
.sans-serif-medium(){
  font-family: "Heebo", "Helvetica Neue", "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  letter-spacing: 0.01em;
}
.sans-serif-bold(){
  font-family: "Heebo", "Helvetica Neue", "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  letter-spacing: 0.01em;
}
.serif-regular(){
  font-family: "Georgia", "Times", serif;
  font-weight: 300;
}
.icon(){
  font-family: 'FontAwesome';
  font-weight: normal;
}

.rounded() {
  border-radius: 100%;
}
.shadow() {
  box-shadow: @shadow;
}
.darkshadow() {
  box-shadow: @darkshadow;
}
.buttonshadow() {
  box-shadow: @buttonshadow;
}
.form-shadow() {
  box-shadow: @form-shadow;
}
.popup-shadow() {
  box-shadow: @popup-shadow;
}
.text-shadow() {
  text-shadow: @text-shadow;
}
.form-shadow() {
  box-shadow: @form-shadow;
}
.sticky-element-shadow() {
  box-shadow: @sticky-element-shadow;
}

.no-shadow() {
  text-shadow: none;
  box-shadow: none;
}
.gold() {
  background: #c19e67;
  background: linear-gradient(135deg,  #f3e2c7 0%,#c19e67 48%,#b68d4c 52%,#e9d4b3 100%);
}
.juwelogold() {
  background: @juwelogold;
  background: linear-gradient(135deg,  @juwelolightgold 0%,@juwelogold 60%);
}
.yellowgold {
  background: #f0e6ce;
  background: linear-gradient(135deg,  #f0e6ce 0%,#dcc37e 50%,#cea942 51%,#e8d9b0 100%);
}
.rosegold {
  background: #f5e1eb;
  background: linear-gradient(135deg,  #f9eeea 0%,#f1d4d0 50%,#eabfb9 51%,#f9eeea 100%);
}
.whitegold {
  background: #f3f3f3;
  background: linear-gradient(135deg,  #f3f3f3 0%,#e1e1e1 50%,#bababa 51%,#ededed 100%);
}
.silver {
  background: #f3f3f3;
  background: linear-gradient(135deg,  #f3f3f3 0%,#e1e1e1 50%,#bababa 51%,#ededed 75%,#ededed 100%);
}
.titan {
  background: #bababa;
  background: linear-gradient(135deg, #ccc 0%,#999 50%, #999 51%, #ccc 100%);
}
// 3G
.yg-wg-rg {
  background: #c19e67;
  background: linear-gradient(135deg,  #f0e6ce 0%,#cea942 33%,#f3f3f3 34%,#bababa 66%,#f9eeea 64%,#eabfb9 100%);
}

// WR
.wg-rg {
  background: #c19e67;
  background: linear-gradient(135deg,  #f3f3f3 0%,#bababa 50%,#f9eeea 51%,#eabfb9 100%);
}

// YW + 2S
.yg-wg {
  background: #c19e67;
  background: linear-gradient(135deg,  #f0e6ce 0%,#cea942 50%,#f3f3f3 51%,#bababa 100%);
}

// YR
.yg-rg {
  background: #c19e67;
  background: linear-gradient(135deg,  #f0e6ce 0%,#cea942 50%,#f9eeea 51%,#eabfb9 100%);
}


.fadePinkToLighter {
  background: @pink;
  background: linear-gradient(90deg,  #861b61 0%, #ab156c 100%);
}
.fadePinkToDarker {
  background: @pink;
  background: linear-gradient(90deg,  #ab156c 0%, #861b61 100%);
}
.fadeBlackToLighter {
  background: #222222;
  background: linear-gradient(90deg,  #222222 0%, #444444 100%);
}
.fadePinkToDarker45 {
  background: @pink;
  background: linear-gradient(45deg,  #ab156c 0%, #861b61 100%);
}
.goldFading {
  background: #e7d289;
  background: linear-gradient(90deg,  #e7d289 0%, #a9793e 100%);
}
.silverFading {
  background: #cccccc;
  background: linear-gradient(90deg,  #cccccc 0%, #999999 100%);
}
.pinkFading {
  background: @pink;
  background: linear-gradient(90deg,  #ab156c 0%, #861b61 100%);
}

.checkmarkgreen() {
/* ~ 15px * 16px */
  &:before {
    position: absolute;
    left: 6px;
    top: 0;
    display: block;
    width: 6px;
    height: 12px;
    border-right: 2px solid @green;
    border-bottom: 2px solid @green;
    transform: rotate(40deg);
    content: ' ';
  }
}
.checkmarkgreenSmall() {
  /* ~ 10px * 10px */
  &:before {
    position: absolute;
    left: 3px;
    top: 0;
    display: block;
    width: 4px;
    height: 8px;
    border-right: 1px solid @green;
    border-bottom: 1px solid @green;
    transform: rotate(40deg);
    content: ' ';
  }
}
.crossmarkred(){
  &:before,
  &:after {
    position: absolute;
    left: 0;
    top: 8px;
    display: block;
    width: 12px;
    height: 0;
    border-bottom: 2px solid @red;
    content: ' ';
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
.crossmarkredSmall(){
  &:before,
  &:after {
    position: absolute;
    left: 0;
    top: 6px;
    display: block;
    width: 7px;
    height: 0;
    border-bottom: 1px solid @red;
    content: ' ';
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
.close-icon(){
  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    &:before,
    &:after {
      position: absolute;
      background-color: @darkgrey;
      content: '';
      transform: rotate(45deg);
    }
    &:before {
      top: 4px;
      left: 14px;
      width: 2px;
      height: 20px;
    }
    &:after {
      top: 13px;
      left: 5px;
      width: 20px;
      height: 2px;
    }
  }
}
.flex-columns(){
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.flex-rows(){
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.flex-item-grow(){
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.flex-item-shrink(){
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.flex-item-adjust(){
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.flex-item-expand(){
  -ms-flex: 2 0 auto;
  flex: 2 0 auto;
}
.max-height-transition(){
  transition: max-height 0.5s;
}

.gem-icons(){
  &:before {
    display: block;
    width: 30px;
    height: 30px;
    margin: 5px 5px 0 0;
    float: left;
    background-image: url('../images/edelsteine-navigation.png');
    background-size: 400%;
    content: '';
  }
  &.diamant:before {
    background-position: 0 0;
  }
  &.rubin:before {
    background-position: -100% 0;
  }
  &.saphir:before {
    background-position: -200% 0;
  }
  &.smaragd:before {
    background-position: -300% 0;
  }
  &.amethyst:before {
    background-position: 0 -100%;
  }
  &.aquamarin:before {
    background-position: -100% -100%;
  }
  &.granat:before {
    background-position: -200% -100%;
  }
  &.topas:before {
    background-position: -300% -100%;
  }
  &.bernstein:before {
    background-position: 0 -200%;
  }
  &.mondstein:before {
    background-position: -100% -200%;
  }
  &.perle:before {
    background-position: -200% -200%;
  }
  &.tuerkis:before {
    background-position: -300% -200%;
  }
  &.apatit:before {
    background-position: 0 -300%;
  }
  &.tansanit:before {
    background-position: -100% -300%;
  }
  &.chalzedon:before {
    background-position: -200% -300%;
  }
  &.kyanit:before {
    background-position: -300% -300%;
  }
  &.iolith:before {
    background-position: 0 -400%;
  }
  &.lapislazuli:before {
    background-position: -100% -400%;
  }
  &.beryll:before {
    background-position: -200% -400%;
  }
  &.citrin:before {
    background-position: -300% -400%;
  }
  &.achat:before {
    background-position: 0 -500%;
  }
  &.muschel:before {
    background-position: -100% -500%;
  }
  &.turmalin:before {
    background-position: -200% -500%;
  }
  &.peridot:before {
    background-position: -300% -500%;
  }
  &.diopsid:before {
    background-position: 0 -600%;
  }
  &.fluorit:before {
    background-position: -100% -600%;
  }
  &.chrysopras:before {
    background-position: -200% -600%;
  }
  &.opal:before {
    background-position: -300% -600%;
  }
  &.alexandrit:before {
    background-position: 0 -700%;
  }
  &.kunzit:before {
    background-position: -100% -700%;
  }
  &.rhodolith:before {
    background-position: -200% -700%;
  }
  &.spinell:before {
    background-position: -300% -700%;
  }
  &.onyx:before {
    background-position: 0 -800%;
  }
  &.zirkon:before {
    background-position: -100% -800%;
  }
  &.quarz:before {
    background-position: -200% -800%;
  }
  &.andalusit:before {
    background-position: -300% -800%;
  }
  &.morganit:before {
    background-position: 0 -900%;
  }
  &.obsidian:before {
    background-position: -100% -900%;
  }
  &.sphen:before {
    background-position: -200% -900%;
  }
  &.labradorit:before {
    background-position: -300% -900%;
  }
  &.larimar:before {
    background-position: 0 -1000%;
  }
  &.sphalerit:before {
    background-position: -100% -1000%;
  }
  &.jade:before {
    background-position: -200% -1000%;
  }
  &.feueropal:before {
    background-position: -300% -1000%;
  }
  &.pietersit:before {
    background-position: 0% -1100%;
  }
  &.moldavit:before {
    background-position: -100% -1100%;
  }
  &.spektrolith:before {
    background-position: -200% -1100%;
  }
  &.stern-saphir:before {
    background-position: 0% -1200%;
  }
  &.turmalin-katzenauge:before {
    background-position: -100% -1200%;
  }
}

/*

wenn der Spinner bei großen Schriftgrößen anfängt zu eiern,
schafft ein ergänzendes padding-bottom Abhilfe:
font-size: 40px; -> padding-bottom: 1px;

*/
.spinner(){
  &:after {
    @keyframes spin {
      from {transform: rotate(0deg);}
      to {transform: rotate(360deg);}
    }
    display: inline-block;
    width: 1em;
    height: 1em;
    text-align: center;
    font-family: @icon;
    font-size: 20px;
    line-height: 1em;
    content: '\f110';
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: steps(8);
    padding-bottom: percentage((1/400));
  }
}