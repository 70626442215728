// brands slider
.brands-slider {
  position: relative;
  left: 0;
  width: auto;
  margin: 0 5px 30px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  text-align: center;
  white-space: nowrap;
  .brands-slides {
    li {
      position: relative;
      display: inline-block;
      flex: 1 0 100%;
      box-sizing: border-box;
      float: none;
      white-space: normal;
      padding: 0;
      font-size: 0;
      line-height: 0;
      a {
        position: relative;
        display: block;
        padding-top: 140%;
        .x-image-container {
          top: 0;
          left: 5px;
          right: 5px;
        }
      }
    }
  }
  .slider-control {
    &.prev {
      left: 5px;
    }
    &.next {
      right: 5px;
    }
  }
}
@media screen and (min-width: 360px) {
  .brands-slider {
    .brands-slides {
      li {
        flex: 1 0 percentage((1/2));
      }
    }
  }
}
@media screen and (min-width: 640px) {
  .brands-slider{
    margin: 0 40px 30px;
    .slider-control {
      &.prev {
        left: -35px;
      }
      &.next {
        right: -35px;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .brands-slider{
    margin: 0 40px 70px;
    .slider-control-large {
      margin: 5px 0 0;
    }
  }
}
@media screen and (min-width: 769px) {
  .brands-slider {
    .brands-slides {
      li {
        flex: 1 0 percentage((1/3));
        padding: 0;
        a {
          .x-image-container {
            left: 10px;
            right: 10px;
          }
        }
      }
    }
    .slider-control-large {
      margin: -5px 0 0;
    }
  }
}
@media screen and (min-width: 1025px) {
  .brands-slider {
    max-width: 1120px;
    .brands-slides {
      li {
        flex: 1 0 percentage((1/4));
      }
    }
  }
}
@media screen and (min-width: 1200px){
  .brands-slider {
    margin: 0 auto 70px;
    padding: 0 10px;
  }
}