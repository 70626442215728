
.horizontal-slider {
  position: relative;
  display: block;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    left: 0;
    width: auto;
    font-size: 0;
    line-height: 0;
    white-space: nowrap;
    text-align: center;
    li {
      display: inline-block;
      flex: 0 0 50%;
      white-space: normal;
      vertical-align: top;
      .details-box {
        position: relative;
        .materials {
          height: 29px;
          padding-bottom: 5px;
          .base-material {
            display: inline-block;
            float: none;
            font-size: 13px;
          }
          .material-x {
            display: inline-block;
            float: none;
            vertical-align: top;
          }
        }
      }
    }
  }
  &.scroll-lock {
    overflow-x: hidden;
  }
}
.slider-control {
  position: absolute;
  top: 50%;
  display: inline-block;
  z-index: 20;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: -10px 0 0;
  &:before,
  &:after {
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    content: '';
    border-left-width: 1px;
    border-left-style: solid;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  &.prev {
    left: 0;
    &:before {
      top: 4px;
      left: 4px;
      transform: rotate(45deg);
      border-color: @darkgrey;
    }
    &:after {
      top: 4px;
      left: 2px;
      transform: rotate(45deg);
      border-color: @white;
    }
  }
  &.next {
    right: 0;
    &:before {
      top: 4px;
      left: 4px;
      transform: rotate(225deg);
      border-color: @white;
    }
    &:after {
      top: 4px;
      left: 2px;
      transform: rotate(225deg);
      border-color: @darkgrey;
    }
  }
  &.slider-control-large {
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    &:before,
    &:after {
      width: 24px;
      height: 24px;
    }
    &.prev {
      left: 10px;
      &:before {
        top: 11px;
        left: 11px;
      }
      &:after {
        top: 11px;
        left: 9px;
      }
    }
    &.next {
      right: 8px;
      &:before {
        top: 11px;
      }
      &:after {
        top: 11px;
      }
    }
  }
  &.disabled {
    cursor: default;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .horizontal-slider {
    overflow: hidden;
  }
}
@media screen and (min-width: 640px) {
  .horizontal-slider {
    ul {
      li {
        flex: 0 0 percentage((1/3));
      }
    }
  }
}
@media screen and (min-width: 900px) {
  .horizontal-slider {
    ul {
      li {
        flex: 0 0 percentage((1/4));
      }
    }
  }
  .slider-control {
    &.slider-control-large {
      &.prev {
        left: 10px;
      }
      &.next {
        right: 10px;
      }
    }
  }
}
@media screen and (min-width: 1025px) {
  .horizontal-slider {
    ul {
      li {
        flex: 0 0 20%;
      }
    }
  }
}

.products-slider {
  position: relative;
  text-align: center;
  margin: 0 25px 30px;
  padding: 0;
  .slider-control {
    &.prev {
      left: -15px;
    }
    &.next {
      right: -15px;
    }
  }
}
@media screen and (min-width: 640px) {
  .products-slider {
    margin: 0 35px 30px;
    .slider-control {
      &.prev {
        left: -25px;
      }
      &.next {
        right: -25px;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .products-slider {
    margin: 0 35px 50px;
  }
}
@media screen and (min-width: 769px) {
  .products-slider {
    .slider-control {
      &.prev {
        left: -20px;
      }
      &.next {
        right: -20px;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .products-slider {
    margin: 0 calc((100% - 1120px)/2) 50px;
  }
}