/* hero teaser */
.hero-teaser {
  position: relative;
  display: block;
  margin: 0 0 30px;
  padding: 0 10px;
  img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
  .hero-slides {
    position: relative;
    left: 0;
    width: auto;
    padding: 0;
    font-size: 0;
    line-height: 0;
    white-space: nowrap;
    text-align: center;
    li {
      position: relative;
      display: inline-block;
      flex: 1 0 100%;
      float: none;
      white-space: normal;
      padding-top: 127.9838709%;
      a {
        display: block;
      }
    }
  }
  .slider-nav-dots {
    text-align: center;
    .slide-nav-dot {

      display: inline-block;

      span {
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 7px;
        border: 1px solid @white;
        background-color: @darkgrey;
      }

      cursor: pointer;
      padding: 3px;
      &.active {
        span {
          width: 27px;
        }
      }
    }
  }
}
@media screen and (min-width: 640px) {
  .hero-teaser {
    .hero-slides {
      li {
        padding-top: percentage((1/3));
      }
    }
    .slider-control {
      &.slider-control-large {
        margin: -30px 0 0;
        &.prev {
          left: 30px;
        }
        &.next {
          right: 30px;
        }
      }
    }
    .slider-nav-dots {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }
}
@media screen and (min-width: 768px){
  .hero-teaser {
    margin: 0 auto 70px;
    padding: 5px 10px 0;
  }
}
@media screen and (min-width: 769px){
  .hero-teaser {
    padding: 10px 20px 0;
  }
}
@media screen and (min-width: 1024px){
  .hero-teaser {
    padding-top: 0;
  }
}
@media screen and (min-width: 1200px){
  .hero-teaser {
    max-width: 1160px;
  }
}


