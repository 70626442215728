/* finder */

#finder {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  text-align: center;
}

/* steps */

.finder-steps {
  position: relative;
  width: 300%;
  font-size: 11px;
  line-height: 15px;
  transition: margin-left 0.5s;
  .finder-step-gemstone {
    display: none;
  }
  &.s1 {
    margin-left: 0;
  }
  &.s2 {
    margin-left: -100%;
  }
  &.s3 {
    margin-left: -200%;
  }
  &.s4 {
    margin-left: -200%;
    .finder-step-gemstone {
      display: block;
    }
    .finder-step-price h2 {
      opacity: 0;
    }
  }


  .finder-step {
    position: relative;
    float: left;
    box-sizing: border-box;
    width: percentage((1/3));
    .finder-step-title {
      height: 44px;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: .02em;
      text-align: center;
      text-transform: none;
      margin: 0;
    }
  }

  /* gemstone step as overlay */

  .finder-step-gemstone {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background-color: rgba(255,255,255,0.8);
  }

  /* gold / silver dependencies */

  &.silver {
    background: @white;
    .silver {
      z-index: 1;
    }
    .finder-step-jewelry {
      .silver {
        z-index: 1;
        background: transparent;
      }
      .gold {
        z-index: -1;
      }
      .all {
        z-index: -1;
      }
    }
    .finder-step-price {
      .finder-step-option-image.gold {
        z-index: -1;
      }
      .finder-step-option-title.gold {
        display: none;
      }
      .finder-step-option-image.gold {
        z-index: -1;
      }
      .finder-step-option-title.silver {
        display: block;
      }
      .finder-step-option-image.silver {
        z-index: 1;
      }
    }
  }
  &.gold {
    background: @white;
    .finder-step-jewelry {
      .silver {
        z-index: -1;
      }
      .gold {
        z-index: 1;
      }
      .all {
        z-index: -1;
      }
    }
    .finder-step-price {
      .finder-step-option-title.silver {
        display: none;
      }
      .finder-step-option-image.silver {
        z-index: -1;
      }
      .finder-step-option-title.gold {
        display: block;
      }
      .finder-step-option-image.gold {
        z-index: 1;
      }
    }
  }
  &.all {
    background: @white;
    .finder-step-jewelry {
      &.all,
      .silver {
        z-index: -1;
      }
      .gold {
        z-index: -1;
      }
      .all {
        z-index: 1;
      }
    }
  }
}
@media screen and (min-width: 360px){
  .finder-steps {
    font-size: 13px;
    line-height: 15px;
  }
}
@media screen and (min-width: 480px){
  .finder-steps {
    .finder-step {
      .finder-step-title {
        height: 22px;
        margin: 0 0 10px;
      }
    }
  }
}
@media screen and (min-width: 640px){
  .finder-steps {
    font-size: 12px;
    line-height: 15px;
  }
}
@media screen and (min-width: 768px){
  .finder-steps {
    .finder-step {
      .finder-step-title {
        font-size: 22px;
        margin: 0 0 28px;
      }
    }
  }
}
@media screen and (min-width: 769px){
  .finder-steps {
    .finder-step {
      padding: 0 40px;
    }
  }
}

/* regular options */

.finder-step-option {
  position: relative;
  width: 50%;
  padding-bottom: 50%;
  float: left;
  box-sizing: border-box;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  .finder-step-option-image {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    z-index: 1;
    overflow: hidden;
  }
  .finder-step-option-title {
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 5px;
    z-index: 2;
    display: block;
    box-sizing: border-box;
    padding: 2px 0 0;
    .sans-serif-regular();
    font-size: 13px;
    line-height: 26px;
    text-transform: uppercase;
    color: @white;
    text-align: center;
    background: @juwelogold;
  }
}
@media screen and (min-width: 480px){
  .finder-step-option {
    .finder-step-option-title {
      padding: 2px 0 0;
      font-size: 15px;
      line-height: 30px;
    }
  }
}
@media screen and (min-width: 640px){
  .finder-step-option {
    width: 25%;
    padding-bottom: 25%;
    .finder-step-option-title {
      padding: 2px 0 0;
      font-size: 13px;
      line-height: 24px;
    }
  }
}

@media screen and (min-width: 1024px){
  .finder-step-option {
    .finder-step-option-title {
      padding: 2px 0 0;
      font-size: 15px;
      line-height: 34px;
    }
  }
}


/* color / gemstone */


/* switch high / low price */
.finder-step-extra-option {
  &.high {
    display: none;
  }
}
.high {
  .finder-step-extra-option {
    &.low {
      display: none;
    }
    &.high {
      display: block;
    }
  }
}

.finder-step-gemstone {

  /* extra options button */
  .finder-step-option-button {
    position: absolute;
    left: 10%;
    top: 50%;
    width: 80%;
    padding: 0;
    box-sizing: border-box;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    .finder-step-option-title {
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      .sans-serif-regular();
      font-size: 15px;
      line-height: 50px;
      text-transform: uppercase;
      color: @white;
      text-align: center;
      background: @pink;
    }
    &.finder-step-gemstone-option-1 {
      .finder-step-option-title {
        top: auto;
        bottom: 5px;
      }
    }
    &.finder-step-gemstone-option-0 {
      .finder-step-option-title {
        top: 5px;
        bottom: auto;
      }
    }
  }

  /* extra options list */
  .finder-step-options-extra {
    position: absolute;
    top: 49px;
    left: 5px;
    right: 5px;
    width: auto;
    z-index: 3;
    padding: 15px;
    border: 2px solid @juwelogold;
    background: @white;
    .close {
      right: 0;
      &:after {
        display: none;
      }
      .close-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        &:before,
        &:after {
          position: absolute;
          background-color: @darkgrey;
          content: '';
          transform: rotate(45deg);
        }
        &:before {
          top: 9px;
          left: 19px;
          width: 2px;
          height: 20px;
        }
        &:after {
          top: 18px;
          left: 10px;
          width: 20px;
          height: 2px;
        }
      }
    }
    ul {
      width: 100%;
      .finder-step-extra-option {
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 13px;
        line-height: 23px;
        text-transform: uppercase;
        text-align: left;
        &:before {
          display: block;
          width: 20px;
          float: left;
          margin-right: 5px;
          font-family: @icon;
          font-size: 18px;
          color: @mediumgrey;
          content: '\f096';
        }
        &.checked:before {
          content: '\f046';
        }
        span {
          &:before {
            display: block;
            width: 30px;
            height: 30px;
            margin: 5px 5px 0 0;
            float: left;
            background-size: 400%;
            content: '';
            background-image: url('../images/edelsteine-navigation.png');
          }
          &.diamant:before {
            background-position: 0 0;
          }
          &.saphir:before {
            background-position: -200% 0;
          }
          &.smaragd:before {
            background-position: -300% 0;
          }
          &.granat:before {
            background-position: -200% -100%;
          }
          &.topas:before {
            background-position: -300% -100%;
          }
          &.bernstein:before {
            background-position: 0 -200%;
          }
          &.perle:before {
            background-position: -200% -200%;
          }
          &.tuerkis:before {
            background-position: -300% -200%;
          }
          &.apatit:before {
            background-position: 0 -300%;
          }
          &.tansanit:before {
            background-position: -100% -300%;
          }
          &.turmalin:before {
            background-position: -200% -500%;
          }
          &.peridot:before {
            background-position: -300% -500%;
          }
          &.diopsid:before {
            background-position: 0 -600%;
          }
          &.fluorit:before {
            background-position: -100% -600%;
          }
          &.opal:before {
            background-position: -300% -600%;
          }
          &.kunzit:before {
            background-position: -100% -700%;
          }
          &.zirkon:before {
            background-position: -100% -800%;
          }
          &.quarz:before {
            background-position: -200% -800%;
          }
          &.morganit:before {
            background-position: 0% -900%;
          }
          &.sphen:before {
            background-position: -200% -900%;
          }
          &.diaspor:before {
            background-position:0 -1000%;
          }
          &.sphalerit:before {
            background-position: -100% -1000%;
          }
        }
      }
    }
    .btn-apply-checked-options {
      width: 100%;
      margin: 10px 0;
      background-color: @juwelogold;
      border-color: @juwelogold;
      .no-shadow();
    }
  }
}

@media screen and (min-width: 360px){
  .finder-step-gemstone {

    /* extra options list */
    .finder-step-options-extra {
      ul {
        .finder-step-extra-option {
          line-height: 27px;
        }
      }
    }
  }
}

@media screen and (min-width: 480px){
  .finder-step-gemstone {

    /* extra options list */
    .finder-step-options-extra {
      top: 35px;
      bottom: 0;
      ul {
        .finder-step-extra-option {
          width: percentage((1/2));
          float: left;
          line-height: 37px;
          &:last-child {
            margin: 0 0 0 50%;
          }
        }
      }
      .btn-apply-checked-options {
        position: absolute;
        bottom: 15px;
        left: 15px;
        right: 15px;
        width: auto;
        margin: 0;
      }
    }
  }
}

@media screen and (min-width: 640px){
  .finder-step-gemstone {

    /* regular options */
    .finder-step-option {
      width: 25%;
      padding-bottom: 19%;
    }

    /* extra options button */
    .finder-step-option-button {
      width: 40%;
      &.finder-step-gemstone-option-1 {
        left: 10%;
        padding: 0;
        .finder-step-option-title {
          top: 0;
          bottom: auto;
          right: 5px;
          left: auto;
        }
      }
      &.finder-step-gemstone-option-0 {
        left: 50%;
        padding: 0;
        .finder-step-option-title {
          top: 0;
          bottom: auto;
          left: 5px;
          right: auto;
        }
      }
    }

    /* extra options list */
    .finder-step-options-extra {
      width: auto;
      padding-bottom: 0;
      bottom: -80px;
      ul {
        width: 90%;
        margin: 0 0 0 10%;
        .finder-step-extra-option {
          width: percentage((1/3));
          float: left;
          line-height: 30px;
          &:last-child {
            margin: 0;
          }
        }
      }
      .btn-apply-checked-options {
        left: 20%;
        right: 20%;
      }
    }
  }
  .finder-step-metal {
    .finder-step-option {
      &.finder-step-metal-option-1 {
        margin-left: 12.5%;
      }
      &.finder-step-metal-option-3 {
        margin-right: 12.5%;
      }
    }
  }
}

@media screen and (min-width: 768px){
  .finder-step-gemstone {

    /* regular options */
    .finder-step-option {
      padding-bottom: 20%;
    }

    /* extra options list */
    .finder-step-options-extra {
      top: 55px;
      ul {
        width: 86%;
        margin: 0 0 0 14%;
        .finder-step-extra-option {
          line-height: 35px;
        }
      }
    }
  }
}

@media screen and (min-width: 769px){
  .finder-step-gemstone {

    /* regular options */
    .finder-step-option {
      padding-bottom: 19%;
    }

    /* extra options list */
    .finder-step-options-extra {
      left: 40px;
      right: 40px;
      ul {
        .finder-step-extra-option {
          line-height: 29px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px){
  .finder-step-gemstone {

    /* regular options */
    .finder-step-option {
      padding-bottom: 20%;
    }

    /* extra options list */
    .finder-step-options-extra {
      ul {
        width: 78%;
        margin: 0 0 0 18%;
        .finder-step-extra-option {
          line-height: 41px;
        }
      }
      .btn-apply-checked-options {
        left: 25%;
        right: 25%;
        line-height: 37px;
      }
    }
  }
}


/* navigation tabs */

.finder-navigation {

  margin: 20px 0 60px;

  .finder-navigation-step {
    position: relative;
    box-sizing: border-box;
    display: block;
    width: percentage((1/3));
    margin: 0;
    padding: 0;
    float: left;
    .sans-serif-regular();
    font-size: 13px;
    text-align: center;
    color: @juwelolightgold;
    cursor: pointer;
    border-color: @juwelolightgold;
    &-jewelry {
      width: 25%;
    }
    &-metal {
      width: 50%;
    }
    &-price {
      width: 25%;
    }
    .finder-navigation-step-number {
      position: relative;
      z-index: 2;
      display: block;
      width: 32px;
      height: 32px;
      margin: 0 auto 10px;
      .sans-serif-regular();
      line-height: 36px;
      text-align: center;
      border-width: 1px;
      border-style: solid;
      .rounded();
      background-color: @white;
    }
    .finder-navigation-step-title {
      display: block;
      padding: 0 5px;
      line-height: 16px;
    }
    .line {
      position: absolute;
      top: 18px;
      display: block;
      width: 50%;
      &:before {
        display: block;
        content: '';
        border-top-width: 1px;
        border-top-style: solid;
        border-color: @juwelolightgold;
      }
      &.line-left {
        &:before {
          margin: 0 26px 0 0;
        }
      }
      &.line-right {
        left: 50%;
        &:before {
          margin: 0 0 0 26px;
        }
      }
    }


    &.finder-navigation-step-active {
      color: @juwelogold;
      border-color: @juwelogold;
      /* line color
      .line-left {
        &:before {
          border-color: @juwelogold;
        }
      } */
    }
    &.finder-navigation-step-selected {
      color: @juwelogold;
      border-color: @juwelogold;
      .finder-navigation-step-number {
        font-size: 0;
        &:before {
          display: inline-block;
          width: 8px;
          height: 16px;
          margin: 5px 0 0;
          content: '';
          transform: rotate(45deg);
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-right-width: 1px;
          border-right-style: solid;
        }
      }
    }
  }
}

@media screen and (min-width: 480px){
  .finder-navigation {
    .finder-navigation-step {
      .finder-navigation-step-title {
        font-size: 15px;
      }
    }
  }
}

@media screen and (min-width: 769px){
  .finder-navigation {
    padding: 0 40px;
  }
}

.finder-complete {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  padding: 20% 0;
  background-color: rgba(255,255,255,0.75);
  p {
    margin: 0;
    .sans-serif-bold();
    font-size: 15px;
    line-height: 20px;
    text-align: center;
  }
}