/* m teaser */
.m-teasers {
  padding: 0 10px;
  margin: 0 0 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
}
.m-teaser {
}
@media screen and (min-width: 640px){
  .m-teasers {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, max-content);
    grid-column-gap: 10px;
    grid-row-gap: 0;
    align-items: center;
    h2 {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column: span 2;
    }
    .m-teaser {
      grid-column-start: 2;
      grid-column-end: 3;
      &:first-of-type:not(.m-teaser-large){
        align-self: self-start;
      }
      &:nth-of-type(2):not(.m-teaser-large){
        align-self: center;
      }
      &:nth-of-type(3):not(.m-teaser-large){
        align-self: self-end;
      }
    }
    .m-teaser-large {
      grid-row-start: 2;
      grid-row-end: 5;
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }
}
@media screen and (min-width: 768px){
  .m-teasers {
    margin: 0 40px 70px;
  }
}
@media screen and (min-width: 769px){
  .m-teasers {
    grid-column-gap: 20px;
  }
}
@media screen and (min-width: 1024px){
  .m-teasers {
    max-width: 960px;
    margin: 0 auto 70px;
    padding: 0 10px;
  }
}