.category-tiles {
  padding: 0;
  .col {
    a {
      position: relative;
      display: block;
      padding: 50% 0 3%;
    }
    &:nth-child(2) {
      a:first-child{
        padding-top: 103.09278%;
      }
    }
  }
}
@media screen and (min-width: 540px) {
  .category-tiles {
    .col {
      width: percentage((1/3));
      &:first-child {
        width: percentage((1/3));
      }
      &:last-child {
        display: block;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .category-tiles {
    .col {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
}
