
// bubbles
.bubbles-banner {
  position: relative;
  min-height: 75px;
  padding: 0 30px;

  ul {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    overflow-x: auto;
    padding: 10px 0;
    li {
      flex: 0 0 90px;
      margin: 0;

      a {
        display: block;
        line-height: 20px;
        text-align: center;
        color: @darkgrey;
        user-select: none;

        picture {
          display: block;
          width: 50px;
          height: 50px;
          margin: 0 auto;
          border-radius: 100%;

          img {
            border-radius: 100%;
          }
        }

        span {
          display: block;
          padding: 5px 0 0;
          .sans-serif-medium();
          font-size: 11px;
        }
      }
    }
  }
  ol {
    justify-content: center;
    display: flex;
    flex-direction: row;
    li {
      position: relative;
      flex: 0 0 16px;
      height: 16px;
      margin: 0 5px 10px;
      &:after {
        position: absolute;
        top: 3px;
        left: 3px;
        display: block;
        width: 8px;
        height: 8px;
        content: '';
        border: 1px solid @mediumgrey;
        border-radius: 100%;
      }
      &.current {
        border: 1px solid @mediumgrey;
        border-radius: 100%;
        &:after {
          background-color: @mediumgrey;
        }
      }
    }
  }
}
@media screen and (min-width: 480px) {
  .bubbles-banner {
    &.bubblecount-4 {
      ul {
        justify-content: center;
      }

      .prev,
      .next {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 580px) {
  .bubbles-banner {
    &.bubblecount-5 {
      ul {
        justify-content: center;
      }

      .prev,
      .next {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 680px) {
  .bubbles-banner {
    &.bubblecount-6 {
      ul {
        justify-content: center;
      }

      .prev,
      .next {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 780px) {
  .bubbles-banner {
    &.bubblecount-7 {
      ul {
        justify-content: center;
      }

      .prev,
      .next {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 880px) {
  .bubbles-banner {
    &.bubblecount-8 {
      ul {
        justify-content: center;
      }

      .prev,
      .next {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 980px) and (max-width: 1023px) {
  .bubbles-banner {
    &.bubblecount-9 {
      ul {
        justify-content: center;
      }

      .prev,
      .next {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .bubbles-banner {
    padding: 0 30px;

    ul {
      padding: 20px 0 10px;
      li {
        flex: 0 0 120px;

        a {
          picture {
            width: 75px;
            height: 75px;
          }

          span {
            font-size: 13px;
          }

          &:hover picture {
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}

#category-bubbles,
.cms-index-index {
  .bubbles-banner {
    margin: 0 0 10px;
    background-color: #EFE9E2;
    ul {
      li {
        a {
          picture {
            width: 100px;
            height: 100px;
            border: 1px solid @white;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  #category-bubbles,
  .cms-index-index {
    .bubbles-banner {
      padding: 0;
      ul {
        max-width: 1140px;
        margin: 0 auto;
        padding: 16px 0;
        li {
          flex: 1 0 auto;
          a {
            picture {
              width: 120px;
              height: 120px;
            }
          }
        }
      }
    }
  }
  #category-bubbles {
    .bubbles-banner {
      margin: 0 0 20px;
    }
  }
  .cms-index-index {
    .bubbles-banner {
      margin: -10px 0 20px;
    }
  }
}

.catalog-category-view {
  .bubbles-banner {
    padding: 20px 30px 10px;
  }
  &.de {
    #category-bubbles {
      .bubbles-banner {
        margin: 0 0 -10px;
        padding: 0 30px;
        background-color: @white;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .catalog-category-view {
    .bubbles-banner {
      padding: 20px 30px;
    }
    &.de {
      #category-bubbles {
        .bubbles-banner {
          margin: 0 0 -10px;
          padding: 0 30px;
          background-color: @white;
        }
      }
    }
  }
}

.c-slider {
  ul {
    scroll-behavior: smooth;
    scrollbar-width: none;
  }
  .prev,
  .next {
    position: absolute;
    top: calc(50% - 1rem);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    border-radius: 100%;
    background-color: transparent;
    border: none;
    &:before,
    &:after {
      position: absolute;
      top: 6px;
      display: block;
      width: 1rem;
      height: 1rem;
      border: 1px solid transparent;
      content: '';
      transform-origin: center;
    }
    &:disabled {
      opacity: .1;
    }
  }
  .prev {
    left: 0;
    &:before,
    &:after {
      transform: rotate(45deg);
    }
    &:before {
      left: 10px;
      border-left-color: @white;
      border-bottom-color: @white;
    }
    &:after {
      left: 11px;
      border-left-color: @black;
      border-bottom-color: @black;
    }
  }
  .next {
    right: 0;
    &:before,
    &:after {
      transform: rotate(-45deg);
    }
    &:before {
      right: 10px;
      border-right-color: @white;
      border-bottom-color: @white;
    }
    &:after {
      right: 11px;
      border-right-color: @black;
      border-bottom-color: @black;
    }
  }
}